// Research.js

import React, { Component } from 'react';
import Header from './Header'; // Import the Header component
import './css/Research.css'; // Make sure to create a Research.css file with your styles
import Footer from './Footer';

class Glossary extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="title-container">
        <h1 className="research-title"><u className="underline">Glossary</u> of Terms</h1>
        </div>
        {/* Repeat this section for each research study */}
        <section className="research-study">
          <p><b>Cognitive Response Cycle:</b> This concept encapsulates the idea that the nervous system makes sense of the world through generating predictions which, in turn, drive responses, which drive results. Those results feed back into future predictions. This drives habitual patterns people follow in their lives. It is these Predictive Response Cycles that CRT helps people to influence, thereby influencing the predictions and ultimately the results people obtain in their lives.</p>
          <p><b>Cognitive Response Pattern:</b> These are the patterned responses people follow that create the cycles described by Cognitive Response Cycles.</p>
          <p><b>Response State: </b>This refers to the overall state of the body at any given time. It is a "snapshot" of the state of the body and directly influences the predictions made by the implicit cognitive processes. It is what is referred to as interoception in the predictive coding research.
          </p>
          <p><b>Mental Core: </b>This refers to the accumulated attributes, beliefs, and values a person holds. These are adaptive or maladaptive aspects of the person's psychological structure that can be indicated by behavioral patterns the person exhibits and is part of the factors that generate the predictions driving responses.
          </p>
          <p><b>Language Association and Categorization System (LACS): </b>This is the main therapeutic asset that is generated through the initial phases of the CRT framework. It is created by taking self-reported language and categorizing that language using Cognitive Traps, cognitive biases, and virtues. Associations between language constructs are identified by observing commonality between those language constructs.
          </p>
          <p><b>Cognitive Traps: </b>These are categories identified in research studies as indicating negative language constructs or language constructs that are characterized as having a negative impact on a person's mental wellness.</p>
          <p><b>Psychological Structure: </b>This refers to the entierty of the accumulated psychological "assets" that drives the predictions generated by the mind and therefore the responses and behaviors the person exhibits.</p>
        </section>
        
        <Footer />
      </div>
    );
  }
}

export default Glossary;
