import React, { Component } from 'react';
import './css/main.css';
import Header from './Header';
import Footer from './Footer';
class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        {/* Hero section */}

        <div className="hero">
          <div className="therapy-title">
            <h1>Cognitive Response Therapy</h1>
            <h3 className="tagline">Be Better Do Better Live Better</h3>
          </div>
        </div>
        <div className="feature-section">
  <h1 className="section-title"><u className="underline">Empower</u> your practice with CRT!</h1>
  <div className="feature-list">
    <div className="feature-column">
      <p><i className="checkmark">✓</i> Easily integrated into existing practices</p>
      <p><i className="checkmark">✓</i> Addresses foundational challenges</p>
    </div>
    <div className="feature-column">
      <p><i className="checkmark">✓</i> Existing programs are available</p>
      <p><i className="checkmark">✓</i> Evidence based and research driven</p>
    </div>
  </div>
  <p className="feature-description">
  Join many other mental health practitioners and coaches that have learned how to incorporate Cognitive Response Therapy into their existing practice. Training workshops are offered monthly with self-directed training coming soon!
  </p>
</div>
<section className="intro-section">
          <div className="container">
  <h1 className="section-title"><u className="underline">What is</u> Cognitive Response Therapy?</h1>
  <p className="feature-description">
  Cognitive Response Therapy defines a framework that helps people improve their quality of life. 
  The underlying principles are based on the science of change through influencing the unconscious processes that drive the majority of the human experience. 
  The focus is on predictive coding which postulates that the brain is constantly generating and updating a "mental model" of the environment.  
  It is the process of prediction that CRT teaches people to influence towards living a better life. 
  Many therapeutic approaches concentrate on what the person is consciously aware of and can report on. CRT brings awareness to what the person is currently unaware of by leveraging language through what is referred to as the Language Association and Categorization System (LACS). 
  The person learns to train the prediction process by leveraging the LACS and disrupt what is referred to as Predictive Response Cycles, helping them live the life they want as opposed to the life they have been driven to lead.  </p>
  <h2 className="phase-title">Main Principles</h2>
  <div className="feature-list">
    <div className="feature-column">
      <p><i className="checkmark">✓</i> The majority of the human experience is driven by processes below conscious awareness</p>
      <p><i className="checkmark">✓</i> Self-reported language can be used to gain insights into a person’s psychological structure increasing clarity for introspection and enabling more positive therapeutic transference and communication</p>
      <p><i className="checkmark">✓</i> A focus on who the person wants to be is equally important as focusing on who the person is and their previous experiences</p>
      <p><i className="checkmark">✓</i> Understanding how the person wants to experience life is as necessary as understanding how they are experiencing life now so that a strategy can be used to close that gap</p>

    </div>
    <div className="feature-column">
      <p><i className="checkmark">✓</i> Mental health lies on a continuum and there is not a single right way to experience life</p>
      <p><i className="checkmark">✓</i> The answer to, “How well do you manage and respond to the physiological changes and responses of your body?” speaks to how effective someone is in their life.</p>
      <p><i className="checkmark">✓</i> Improving the person’s ability to manage and influence their own physiological responses is key to helping a person live the life they want</p>
      <p><i className="checkmark">✓</i>  Helping a person understand the science or the “why” behind their challenges helps them in their journey of change</p>

    </div>
    
  </div>
</div>
</section>


        <section className="phases-section">
          <div className="container">
            <h2 className="section-title"><u className="underline">The 6 Phases</u> of Cognitive Response Therapy</h2>
            <div className="phases-grid">
              {/* Repeat for each phase */}
              <div className="phase">
                <h2 className="phase-title" style={{ background: "#f9f9f9", color: "#00006f" }}>1 Prepare</h2>
                <p className="phase-description">
                  The basic science is introduced helping the person to gain an understanding for what currently drives their responses to life. This includes physiological and situational/interpersonal awareness.
              </p>
              </div>
              <div className="phase">
                <h2 className="phase-title" style={{ background: "#0006F", color: "#00006f" }}>2 Define</h2>
                <p className="phase-description">
                The essential parts of a person's psychological structure is initially defined and creates the basis for the Language Association and Categorization System (LACS).                </p>
              </div>
              <div className="phase">
                <h2 className="phase-title" style={{ background: "#0006F", color: "#00006f" }}>3 Discover</h2>
                <p className="phase-description">
                Leveraging the LACS, a person discovers more about themselves and gains clarity about who they are now and who they need to be in order to live the life they want.                </p>
              </div>
              <div className="phase">
                <h2 className="phase-title" style={{ background: "#0006F", color: "#00006f" }}>4 Resolve</h2>
                <p className="phase-description">
                Opportunities are identified to resolve the inner dissonance or Core Disharmonies that drive negative Predictive Response Cycles and negative Response States of the body.                </p>
              </div>
              <div className="phase">
                <h2 className="phase-title" style={{ background: "#0006F", color: "#00006f" }}>5 Retrain</h2>
                <p className="phase-description">
                Leveraging the LACS and the newly gained clarity, a person learns to influence and eventually train the prediction process to generate more appropriate amounts of energy and better information as part of the predictions resulting in better Predictive Response Cycles and therefore, better life results.                </p>
              </div>
              <div className="phase">
                <h2 className="phase-title" style={{ background: "#0006F", color: "#00006f" }}>6 Strengthen</h2>
                <p className="phase-description">
                The person continues to live these new discoveries and practices what has been learned to solidify the new Predictive Response Cycles increasing their subjective well being.                </p>
              </div>
              {/* ... other phases */}
            </div>
          </div>
        </section>
        <section className="testimonial-section">
          <div className="container">
            <h2 className="section-title">Testimonials</h2>
            <div className="testimonial-grid">
              {/* Repeat this block for each testimonial */}
              <div className="testimonial">
                <p className="testimonial-content">
                "After taking Elliot's training on Cognitive Response Therapy I was able to apply those principles to my coaching workshops where I empower women in leadership positions. What Elliot has taught me has been instrumental in improving what I'm teaching and the impact I've been able to have on my clients."                </p>
                <p className="testimonial-author">- Dr. Laura Sydel CCI</p>
              </div>
              <div className="testimonial">
                <p className="testimonial-content">
                "I have been a therapist for over 15 years and felt there was more I could do to help my clients. Cognitive Response Therapy ressonated with me. I like the phased-approach. I have been able to walk my clients through an actionable approach where they can see some immediate progress and move towards living a life they never thought possible. Thank you Elliot!"                </p>
                <p className="testimonial-author">-- Dr. Jane Bernard, Clinical Psychologist</p>
              </div>
              <div className="testimonial">
                <p className="testimonial-content">
                "Elliot's practical and applied approach is very impressive to me. I have been a psychiatrist for 20 years and have found that my industry can be dogmatic and stuck in their ways. CRT was the shot in the arm I needed to take a fresh approach to the counseling side of what I do. It has been a game changer for me and my patients."                </p>
                <p className="testimonial-author">- Dr. Rick Spenagle, Psychiatrist</p>
              </div>              
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Home;
